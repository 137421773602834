import React from 'react'

import { Box } from 'components/Box'
import { Table } from 'components/Table'
import { round } from 'utils/round'
import { usePersonalAccountFinanceStyles } from './styles'

interface PersonalAccountFinanceProps {
  className?: string
  balance: number
  accrualsForLastPeriod: number
}

const showPlaceholder = true

export const PersonalAccountFinance: React.FC<PersonalAccountFinanceProps> = ({
  className,
  balance,
  accrualsForLastPeriod,
}) => {
  const classes = usePersonalAccountFinanceStyles()

  if (showPlaceholder) {
    return (
      <Box className={className}>
        <p className={classes.text}>Немає зʼєднання з білінгом</p>
      </Box>
    )
  }

  return (
    <Box className={className}>
      <Table<{ id: string; value: number | string }>
        columns={[
          { id: 'id', label: 'Balance', width: '100%' },
          { id: 'value', label: 'Accruals', className: classes.bold },
        ]}
        data={[
          { id: 'Сальдо за О/Р на поточну дату, грн:', value: round(balance) },
          { id: 'Нарахування за останній період, грн:', value: round(accrualsForLastPeriod) },
        ]}
      />
    </Box>
  )
}
