import { makeStyles } from '@fluentui/react'
import { colors } from 'styles/variables'

export const usePersonalAccountFinanceStyles = makeStyles({
  bold: {
    fontWeight: 'bold !important',
  },
  text: {
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '28px',
    color: colors.grey14,
    margin: 0,
  },
})
