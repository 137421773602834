import React from 'react'
import { PrimaryButton } from '@fluentui/react'
import format from 'date-fns/format'

import { MeterType } from 'types'
import { Box } from 'components/Box'
import { Table } from 'components/Table'
import type {
  PersonalAccountMeterReading,
  OneZoneMeterReading,
  TwoZoneMeterReading,
  ThreeZoneMeterReading,
} from 'store/personal-accounts'
import { getMeterTypeLabel } from 'config/meterType'

import { groupMeterReading } from './utils'
import { usePersonalAccountMeterReadingsStyles } from './styles'

interface PersonalAccountMeterReadingsProps {
  className?: string
  readings: PersonalAccountMeterReading[]
  onAdd: () => void
}

const renderDate = (date: string) => {
  return format(new Date(date), 'dd.MM.yy')
}

const commonColumns = [
  { id: 'date', label: 'Дата', width: '20%', render: renderDate },
  { id: 'type', label: 'Тип', width: '10%', render: getMeterTypeLabel },
] as const

// remove after implementation
const showBtn = false

export const PersonalAccountMeterReadings: React.FC<PersonalAccountMeterReadingsProps> = ({
  className,
  readings,
  onAdd,
}) => {
  const classes = usePersonalAccountMeterReadingsStyles()

  return (
    <Box className={className}>
      <p>Показання лічильника</p>

      {groupMeterReading(readings).map((group) => {
        if (group.type === MeterType.ONE_ZONE) {
          return (
            <Table<OneZoneMeterReading>
              showHeader
              className={classes.table}
              data={group.readings}
              columns={[...commonColumns, { id: 'consumption', label: 'Показання' }]}
            />
          )
        }

        if (group.type === MeterType.TWO_ZONE) {
          return (
            <Table<TwoZoneMeterReading>
              showHeader
              data={group.readings}
              className={classes.table}
              columns={[
                ...commonColumns,
                { id: 'dayZoneConsumption', label: 'День', width: '20%' },
                { id: 'nightZoneConsumption', label: 'Ніч' },
              ]}
            />
          )
        }

        if (group.type === MeterType.THREE_ZONE) {
          return (
            <Table<ThreeZoneMeterReading>
              showHeader
              data={group.readings}
              className={classes.table}
              columns={[
                ...commonColumns,
                { id: 'peakZoneConsumption', label: 'Пік', width: '20%' },
                { id: 'halfPeakZoneConsumption', label: 'Напівпік', width: '20%' },
                { id: 'nightZoneConsumption', label: 'Ніч' },
              ]}
            />
          )
        }

        return null
      })}
      {showBtn && (
        <PrimaryButton onClick={() => onAdd()} style={{ marginRight: 10 }}>
          Передати показання
        </PrimaryButton>
      )}
      <PrimaryButton href="https://yasno.com.ua/indicators" target="_blank">
        Перейти на сайт Yasno
      </PrimaryButton>
    </Box>
  )
}
